<template>
  <div>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back" v-show="!storeId">
            新增门店
          </i>
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back" v-show="storeId">
            编辑门店
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- 表单 -->
      <el-main style="margin-top: 20px">
        <el-form ref="form" :model="form" label-width="120px" :rules="storeRules" >
          <el-row class="left">
	          <el-col>
              <el-form-item label="关联用户" prop="wx_id">
                <WxUserSelect :wxInfo="wxInfo" @change="handleWxInfoChange" :update="!form.store_id"></WxUserSelect>
              </el-form-item>
	          </el-col>
	        </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="门店logo" prop="store_logo" style="text-align: left">
                <div class="previewUpload">
                  <div v-show="form.store_logo.length>0" class="previewItem" v-for="(item,index) in form.store_logo" :key="index">
                    <img :src="item.img_path" alt="" @click="openUploadDialog(1,'cover')">
                    <i class="el-icon-close delIcon" @click="delCoverItem(index)"></i>
                  </div>
                  <div class="previewItems" v-if="form.store_logo.length==0" @click="openUploadDialog(1,'cover')"><i class="el-icon-plus previewadd"></i></div>
                </div>
                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="门店名称" prop="store_name">
                <el-input
                  v-model="form.store_name"
                  show-word-limit
                  maxlength="15"
                  placeholder="请输入服务名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="left">
            <el-col :span="24">
              <el-form-item label="服务类型:" prop="type_id">
                <el-select v-model="form.type_id" placeholder="请选择服务类型" multiple clearable style="width: 100%;">
                  <el-option
                      v-for="item in typeList"
                      :key="item.type_id"
                      :label="item.type_name"
                      :value="item.type_id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="门店电话" prop="store_phone">
                <el-input
                  v-model="form.store_phone"
                  show-word-limit
                  maxlength="15"
                  placeholder="请输入门店电话"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="left">
            <el-col :span="24">
              <el-form-item label="营业时间" prop="store_time">
								<el-time-picker
								    v-model="form.store_start"
										value-format="HH:mm"
								    :picker-options="{
								      selectableRange: '00:00:00 - ' + form.store_end + ':00'
								    }"
								    placeholder="选择时间" format="HH:mm"></el-time-picker>
										-
								<el-time-picker
								    v-model="form.store_end"
										value-format="HH:mm"
								    :picker-options="{
								      selectableRange: form.store_start + ':00 - 23:59:00'
								    }"
								    placeholder="选择时间" format="HH:mm"></el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="left">
	          <el-col :span="8">
	            <el-form-item label="所属城市" prop="city_id">
	            	<treeselect v-model="form.city_id" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer" :show-count="true" placeholder="请选择所属城市" />
	            </el-form-item>
	          </el-col>
	        </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
	            <el-form-item label="所在地址" prop="address">
	              <el-input v-model="form.address" placeholder="请输入所在地址"></el-input>
	            </el-form-item>
	          </el-col>
	        </el-row>
	        <el-row :gutter="10">
            <el-col :span="8">
	            <el-form-item label="经度" prop="lng">
	              <el-input v-model="form.lng" placeholder="请输入经度"></el-input>
	            </el-form-item>
	          </el-col>
            <el-col :span="8">
	            <el-form-item label="纬度" prop="lat">
	              <el-input v-model="form.lat" placeholder="请输入纬度"></el-input>
	            </el-form-item>
	          </el-col>
	          <el-col :span="4" style="margin:5px 0 0 5px">
	            <el-button type="primary" plain size="mini" @click="getJingweidu">获取经纬度</el-button>
	          </el-col>
	          <Mapjs :jingweiVisible.sync="jingweiVisible" @close="closeMapDialog" @subJINGWEI="subJINGWEI"></Mapjs>
	        </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="门店简介" prop="store_desc">
                <el-input
                  v-model="form.store_desc"
                  show-word-limit
                  maxlength="1000"
                  :rows="10"
                  type="textarea"
                  placeholder="请输入门店简介"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-row :gutter="10">-->
<!--            <el-col :span="24">-->
<!--              <el-form-item label="营业执照" prop="store_yyzz" style="text-align: left">-->
<!--                <div class="previewUpload">-->
<!--                  <div v-show="form.store_yyzz.length>0" class="previewItem" v-for="(item,index) in form.store_yyzz" :key="index">-->
<!--                    <img :src="item.img_path" alt="" @click="openUploadDialog((1-form.store_yyzz.length),'yyzz')">-->
<!--                    <i class="el-icon-close delIcon" @click="delYyzzItem(index)"></i>-->
<!--                  </div>-->
<!--                  <div class="previewItems" v-if="form.store_yyzz.length==0" @click="openUploadDialog((1-form.store_yyzz.length),'yyzz')"><i class="el-icon-plus previewadd"></i></div>-->
<!--                </div>-->
<!--                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-row :gutter="10">-->
<!--            <el-col :span="24">-->
<!--              <el-form-item prop="store_tese" label="门头照" style="text-align: left">-->
<!--                <div class="previewUpload">-->
<!--                  <div v-show="form.store_tese.length>0" class="previewItem" v-for="(item,index) in form.store_tese" :key="index">-->
<!--                    <img :src="item.img_path" alt="" class="previewadd">-->
<!--                    <i class="el-icon-close delIcon" @click="delTeseItem(index)"></i>-->
<!--                  </div>-->
<!--                  <div class="previewItems" @click="openUploadDialog((9-form.store_tese.length),'tese')"><i class="el-icon-plus previewadd"></i></div>-->
<!--                </div>-->
<!--                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>-->
<!--            </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-row :gutter="10">-->
<!--            <el-col :span="24">-->
<!--              <el-form-item prop="store_lunbo" label="环境照" style="text-align: left">-->
<!--                <div class="previewUpload">-->
<!--                  <div v-show="form.store_lunbo.length>0" class="previewItem" v-for="(item,index) in form.store_lunbo" :key="index">-->
<!--                    <img :src="item.img_path" alt="" class="previewadd">-->
<!--                    <i class="el-icon-close delIcon" @click="delCarouselItem(index)"></i>-->
<!--                  </div>-->
<!--                  <div class="previewItems" @click="openUploadDialog((9-form.store_lunbo.length),'carousel')"><i class="el-icon-plus previewadd"></i></div>-->
<!--                </div>-->
<!--                <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>-->
<!--            </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->




          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item label="关联技师">
                <el-button type="primary" icon="el-icon-plus" @click="chooseTechnician">选择技师</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="" prop="relation">
                <el-table :data="form.relation" style="width: 100%" :header-cell-style="{background: '#eef1f6', color: '#606266'}">
                  <el-table-column prop="jishi_id" label="技师ID"> </el-table-column>
                  <el-table-column label="技师头像">
                    <template slot-scope="scope">
                      <div><el-avatar shape="square" size="large" :src="scope.row.gzxxz_img"></el-avatar></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jishi_name" label="技师姓名"></el-table-column>
                  <el-table-column prop="phone" label="手机号"></el-table-column>
                  <el-table-column label="操作" width="100px">
										<template slot-scope="scope">
											<div><el-button type="danger" plain size="mini" @click="remove(scope.$index)">删除</el-button></div>
										</template>
									</el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row :gutter="10">
            <el-col :span="10" style="text-align:left">
              <el-form-item>
                <el-button type="primary" @click="edit">提交</el-button>
                <el-button type="default" @click="back">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <!-- 对话框 -->
      <el-dialog title="关联技师" :visible.sync="dialogVisible" width="60%">
        <el-row :gutter="10" style="margin-bottom: 20px">
          <el-col :span="10">
            <el-input
              placeholder="请输入技师姓名/手机号"
              v-model="technicianText"
              clearable
              @clear="getTechnician"
            >
             <!-- <el-button
                slot="append"
                icon="el-icon-search"
                @click="getTechnician"
              ></el-button> -->
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="getTechnician"
              >搜索</el-button
            >
          </el-col>
        </el-row>
        <!-- 表格 -->
        <el-table ref="xyfwTable"
          @select="select"
          @select-all="selectAll"
          :data="technicianTable"
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="jishi_id" label="技师ID"></el-table-column>
          <el-table-column label="技师头像">
            <template slot-scope="scope">
              <div>
                <el-avatar
                  shape="square"
                  size="large"
                  :src="scope.row.gzxxz_img"
                ></el-avatar>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="jishi_name" label="技师姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
        <el-button type="default" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="relation">确认</el-button>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {serviceTypeAll} from "@/api/request/service";

let that;
let timer=null;
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from '@/components/Pagination/Pagination.vue'
import Upload from '@/components/Upload/Upload.vue'
import Mapjs from '@/components/Map/Map.vue'
import WxUserSelect from "@/components/Form/WxUserSelect.vue";
export default {
  components:{
    WxUserSelect,
    Treeselect,Pagination,Upload,Mapjs
  },
  data() {
  	// 验证时间
    var checkTime = (rule, value, cb) => {
      if(this.form.store_start != null) {
        return cb();
      } else {
        cb(new Error("请选择开始时间"));
      }
      if(this.form.store_end != null) {
        return cb();
      } else {
        cb(new Error("请选择结束时间"));
      }
    };
    // var checkRelation = (rule, value, cb) => {
    //   if (this.form.relation.length!==0) {
    //     return cb();
    //   } else {
    //     cb(new Error("请选择关联技师"));
    //   }
    // };
    return {
      storeId: this.$route.query.id,
      wxInfo: {},
      form: {
      	wx_id: '',
      	relevantUser:{},
        store_name: "",
        store_logo: [],
        // store_yyzz: [],
        // store_lunbo: [],
        // store_tese: [],
        city_id: null,
        store_desc: "",
        store_phone: "",
        store_start: "09:00",
        store_end: "23:00",
        address: "",
        lat: "",
        lng: "",
        relation: [],
        type_id: null
      },
      uploadVisible:false,
      chooseList:[],

      jingweiVisible:false,
      userForm:{
        userInfo:''
      },
      pictureType:'',
      storeRules: {
        wx_id: [{ required: true, message: "关联用户不能为空", trigger: "blur" },],
        store_name: [{ required: true, message: "门店名称不能为空", trigger: "blur" },],
        store_phone: [{ required: true, message: "门店电话不能为空", trigger: "blur" },],
        store_logo: [{ required: true, message: "请上传图片", trigger: "blur" },],
        // store_lunbo: [{ required: true, message: "请上传图片", trigger: "blur" },],
        // store_yyzz: [{ required: true, message: "请上传营业执照", trigger: "blur" },],
        city_id:[{required: true, message:'请选择所属城市', trigger: 'change'},],
        address:[{required: true, message:'请输入所在地址', trigger: 'blur'},],
        lng:[{required: true, message:'请输入经度', trigger: 'blur'},],
        lat:[{required: true, message:'请输入纬度', trigger: 'blur'},],
        store_time: [{required: true,validator: checkTime,trigger: "blur"}, ],
        store_desc: [{ required: true, message: "请输入门店简介", trigger: "blur" },],
        // relation:[{ validator: checkRelation, trigger: "blur" },]
        type_id: [{required: true, message: '请选择服务类型', trigger: 'blur'}],
      },
      dialogVisible: false,
      chooseImgCount:0,
      technicianTable: [],
      technicianText: "",
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total: 0,
      selectdata:[],
      queryUserInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      user_total: 0,
      relevantUserTable:[],
      relevantUserfalse:{},
      city_list: [],
      typeList: [],
      isSubmit: false
    };
  },
  created() {
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getCityList();
			this.getJishiList();
			this.getCustomList();
      this.getServiceType();
			if(this.$route.query.id){
				this.getStoreDetail();
			}
		}
  },
  mounted() {},
  methods: {
    async getServiceType() {
      const res = await serviceTypeAll({})
      if (res.code!==200) this.$message.error(res.msg);
      this.typeList = res.data;
    },
  	getStoreDetail(){
  		var url = 'store/store_detail';
			let params = {
				store_id: this.$route.query.id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					console.log(res.detail);
					if (!res.detail.store_start) {
						res.detail.store_start = "00:00"
					}
					if (!res.detail.store_end) {
						res.detail.store_end = "23:59"
					}
					that.form = res.detail;
          that.wxInfo = res.detail.wx_info ? res.detail.wx_info : {};
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getCityList(){
  		var url = 'city/city_list';
			let params = {
				is_del: 1
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.city_list = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getJishiList() {
			var url = 'jishi/jishi_list';
			let params = {
				//jishi_type: 3,
				jishi_name: this.technicianText,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.technicianTable = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
		},
		getCustomList(){
  		var url = 'custom/user_list';
			let params = {
				nickName: this.userForm.userInfo,
				size: this.queryUserInfo.pagesize,
				page: this.queryUserInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.user_total = res.total;
					that.relevantUserTable = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	//转换菜单数据结构
		normalizer(node) {
			if(node.children && !node.children.length) {
				delete node.children;
			}
			return {
				id: node.city_id,
				label: node.city_name,
				children: node.children,
			};
		},
    receiveRes(chooseList,pictureType){
    	console.log(pictureType);
      if(pictureType==='cover'){
        this.form.store_logo = chooseList;
      }else if(pictureType=='carousel'){
        this.form.store_lunbo = this.form.store_lunbo.concat(chooseList);
      }else if(pictureType=='tese'){
        this.form.store_tese = this.form.store_tese.concat(chooseList);
      }else if(pictureType=='yyzz'){
      	this.form.store_yyzz = this.form.store_yyzz.concat(chooseList)
      }
      this.uploadVisible = false;
    },
    closeDelDialog(){
      this.uploadVisible=false
    },
    back() {
      this.$router.go(-1);
    },
    openUploadDialog(count,pictureType){
      this.pictureType=pictureType
      this.chooseImgCount=count
      this.uploadVisible=true
    },
    delCoverItem(index){
      this.form.store_logo.splice(index,1)
    },
    delYyzzItem(index){
      this.form.store_yyzz.splice(index,1)
    },
    delCarouselItem(index){
      this.form.store_lunbo.splice(index,1)
    },
    delTeseItem(index){
    	this.form.store_tese.splice(index,1)
    },
    getTechnician() {
    	this.getJishiList();
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getJishiList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getJishiList();
    },
    chooseTechnician() {
      this.dialogVisible = true;
    },
    remove(index){
    	this.form.relation.splice(index, 1)
    },
    //关联技师
    relation() {
    	this.dialogVisible = false;
    	let newarr = [...this.form.relation, ...this.selectdata];
			let map = new Map();
			this.form.relation = newarr.filter(item => !map.has(item.jishi_id) && map.set(item.jishi_id, 1))
			this.selectdata = [];
			this.$refs.xyfwTable.clearSelection();
    },
    select(selection, row) {
      this.selectdata = selection;
    },
    selectAll(selection){
      this.selectdata = selection;
    },
    //打开经纬度弹窗
    getJingweidu(){
      this.jingweiVisible=true
    },
    //关闭经纬度弹窗
    closeMapDialog(){
      this.jingweiVisible=false
    },
    //获取经纬度
    subJINGWEI(lat,lng){
      this.form.lat=lat
      this.form.lng=lng
      this.jingweiVisible=false
    },
    handleWxInfoChange(e) {
      this.wxInfo = e;
      this.form.wx_id = e.wx_id;
      if (this.isSubmit) {
        this.$refs.form.validate(valid=>{})
      }
    },
    //提交
    edit(){
      this.isSubmit = true;
      this.$refs.form.validate(valid=>{
        if(!valid) return;
        else{
        	if(timer != null){clearTimeout(timer);}
        	timer = setTimeout(() => {
        		if(that.form.relevantUser.wx_id){
	        		that.form.wx_id = that.form.relevantUser.wx_id;
	        	}
			    	that.form.logo_imgs = JSON.stringify(that.form.store_logo);
			    	// that.form.yyzz_imgs = JSON.stringify(that.form.store_yyzz);
		      	// that.form.lunbo_imgs = JSON.stringify(that.form.store_lunbo);
		      	// that.form.tese_imgs = JSON.stringify(that.form.store_tese);
		      	that.form.jishi_ids = JSON.stringify(that.form.relation);
	        	var url = 'store/addOrUpdate';
		        let params = that.form;
		        that.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			that.$refs.form.resetFields();
	        			that.$message.success("操作成功");
	        			that.$router.push({path: '/store_list'});
	        		}else{
	        			that.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            that.$message.error('网络错误');
		        });
			    }, 1000);
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
	.previewUpload{
	  display: flex;
	  flex-wrap: wrap;
	  .previewItems{
	    width: 178px;
	    height: 178px;
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    margin:0 10px 10px 0;
	    cursor: pointer;
	    position: relative;
	    .previewadd {
	      font-size: 28px;
	      color: #8c939d;
	      width: 178px;
	      height: 178px;
	      line-height: 178px;
	      text-align: center;
	    }
	    &:hover{
	      border-color: #409EFF;
	    }
	  }
	  .previewItem{
	    width: 178px;
	    height: 178px;
	    border-radius: 6px;
	    margin:0 10px 10px 0;
	    cursor: pointer;
	    position: relative;
	    .delIcon{
	      display: none;
	    }
	    &:hover{
	      .delIcon{
	        display: block;
	        position: absolute;
	        top: 5px;
	        right: 5px;
	        color: white;
	        font-size: 30px;
	      }
	    }
	    
	    img{
	      width: 100%;
	      height: 100%;
	    }
	    
	  }
	}
  ::v-deep .vue-treeselect--single .vue-treeselect__input {
    display: block;
  }
</style>